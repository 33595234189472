import * as React from "react";
import FAQItem from "../FAQItem";
import { FAQItem as FAQItemType } from "@/types/index";

export const FAQList: React.FC<{
	list: Array<FAQItemType | undefined>;
}> = ({ list }) => {
	const FAQList = list.map((faq, index) => {
		if (!faq) return null;

		return (
			<span key={index}>
				<FAQItem increment={index + 1} data={faq} />
			</span>
		);
	});

	return <>{FAQList}</>;
};

export default FAQList;
