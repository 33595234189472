import * as React from "react";
import styles from "./styles.module.css";
import { HeaderContent } from "@/types/index";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const HeaderContent: React.FC<{
	data: HeaderContent;
}> = ({ data }) => {
	return (
		<div className={`${styles.hero} mx-0`}>
			<h1 className={styles.hero__title}>{data.fields.title}</h1>
			<div className={`${styles.hero__text}`}>
				{documentToReactComponents(data.fields.content)}
			</div>
		</div>
	);
};

export default HeaderContent;
