import * as React from "react";
import styles from "./styles.module.css";
import { FormError, Heading } from "@/components/Shared";
import useSubscribeMailingList from "@/hooks/useSubscribeMailingList";

const MailingList: React.FC = () => {
	const { loading, error, submitted, formik } = useSubscribeMailingList();

	return (
		<section className={styles.mailing_list}>
			<div className="container-fluid text-center col-sm-8 col-md-6 col-lg-6 col-xl-4">
				<div className={`${styles.mailing_list__container} mx-auto`}>
					<Heading
						variant={2}
						isBold={true}
						title="Receive the latest news and deals straight to your inbox."
					/>
					{!submitted ? (
						<>
							<p className="mt-4 mb-5">
								Subscribe to our news letter to receive the latest news and
								deals for Broadband, Energy and Mobile.
							</p>
							<form onSubmit={formik.handleSubmit}>
								<div className={`${styles.input_button} d-flex`}>
									<input
										className={`${styles.input}`}
										name="email"
										value={formik.values.email}
										onChange={formik.handleChange}
										placeholder="Email..."
									/>
									<button
										className={`${styles.button}`}
										name="subscribe"
										type="submit"
									>
										{loading ? (
											<>
												<span
													className="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"
												></span>
												<span className="visually-hidden">Loading...</span>
											</>
										) : (
											<>Subscribe</>
										)}
									</button>
								</div>

								{formik.touched.email && formik.errors.email ? (
									<FormError
										message="Please enter a valid email."
										theme="dark"
									/>
								) : null}
								{error && <FormError message={error.message} theme="dark" />}
							</form>
						</>
					) : (
						<p className="mt-5">Thank you for subscribing.</p>
					)}
				</div>
			</div>
		</section>
	);
};

export default MailingList;
