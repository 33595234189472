import Link from "next/link";
import * as React from "react";
import Image from "next/image";
import Nav from "@/components/Nav";
import styles from "../styles.module.css";
import { Heading } from "@/components/Shared";

const LandingHeader: React.FC<{ content: any; image: any }> = ({
	content,
	image,
}) => {
	// Move to contentful?

	const buttonData = [
		{
			title: "Broadband & TV",
			link: "/broadband",
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" width="30px" height="28px">
					<path
						fill-rule="evenodd"
						d="M29.118,23.619 C28.530,24.207 27.824,24.501 26.1000,24.500 L20.1000,24.500 L20.1000,27.500 L8.1000,27.500 L8.1000,24.500 L2.1000,24.500 C2.175,24.500 1.468,24.206 0.880,23.618 C0.292,23.030 -0.001,22.324 -0.000,21.500 L-0.000,3.500 C-0.000,2.675 0.294,1.968 0.882,1.380 C1.470,0.792 2.176,0.499 2.1000,0.500 L26.1000,0.500 C27.825,0.500 28.531,0.794 29.119,1.382 C29.707,1.970 30.001,2.676 29.1000,3.500 L29.1000,21.500 C29.1000,22.325 29.706,23.031 29.118,23.619 ZM26.1000,3.500 L2.1000,3.500 L2.1000,21.500 L26.1000,21.500 L26.1000,3.500 ZM12.993,11.505 C14.081,12.592 14.937,13.867 15.562,15.330 C16.187,16.793 16.500,18.350 16.500,19.1000 L14.325,19.1000 C14.326,17.276 13.370,14.957 11.457,13.044 C9.544,11.131 7.225,10.175 4.500,10.175 L4.500,7.1000 C6.151,7.1000 7.707,8.312 9.169,8.937 C10.631,9.562 11.906,10.418 12.993,11.505 ZM11.1000,19.1000 L9.825,19.1000 C9.826,18.526 9.307,17.270 8.269,16.232 C7.231,15.194 5.975,14.675 4.500,14.675 L4.500,12.500 C6.574,12.499 8.342,13.230 9.805,14.693 C11.268,16.156 11.1000,17.925 11.1000,19.1000 ZM7.500,19.1000 L4.500,19.1000 L4.500,16.1000 C5.324,16.999 6.030,17.292 6.618,17.880 C7.206,18.468 7.500,19.175 7.500,19.1000 Z"
					/>
				</svg>
			),
			description: "Compare hundreds of providers",
		},
		// {
		// 	title: "Energy",
		// 	link: "/energy",
		// 	icon: (
		// 		<svg xmlns="http://www.w3.org/2000/svg" width="28px" height="28px">
		// 			<path
		// 				fill-rule="evenodd"
		// 				d="M23.562,23.544 C20.937,26.182 17.750,27.501 13.1000,27.500 C12.400,27.500 10.887,27.237 9.462,26.712 C8.037,26.187 6.750,25.462 5.600,24.537 L2.637,27.500 L0.500,25.362 L3.462,22.400 C2.537,21.250 1.812,19.956 1.287,18.518 C0.762,17.080 0.500,15.574 0.500,13.1000 C0.500,10.250 1.819,7.062 4.457,4.437 C7.095,1.812 10.276,0.500 13.1000,0.500 L27.500,0.500 L27.500,13.1000 C27.500,17.725 26.187,20.906 23.562,23.544 ZM24.500,3.500 L13.1000,3.500 C11.100,3.500 8.625,4.519 6.575,6.557 C4.525,8.595 3.500,11.076 3.500,13.1000 C3.500,16.900 4.525,19.375 6.575,21.425 C8.625,23.475 11.100,24.500 13.1000,24.500 C16.925,24.500 19.406,23.475 21.444,21.425 C23.482,19.375 24.501,16.900 24.500,13.1000 L24.500,3.500 ZM12.012,21.312 C11.887,21.437 11.750,21.500 11.600,21.500 C11.450,21.500 11.312,21.437 11.187,21.312 C11.062,21.188 10.1000,21.057 10.1000,20.919 C10.1000,20.781 11.037,20.650 11.112,20.525 L14.787,15.537 L8.712,14.937 C8.362,14.888 8.137,14.707 8.037,14.394 C7.937,14.081 8.012,13.812 8.262,13.587 L15.987,6.650 C16.111,6.525 16.255,6.462 16.418,6.462 C16.581,6.462 16.725,6.525 16.850,6.650 C16.951,6.776 17.007,6.907 17.019,7.044 C17.031,7.181 16.987,7.312 16.887,7.437 L13.250,12.425 L19.325,13.025 C19.675,13.076 19.900,13.257 19.1000,13.569 C20.100,13.881 20.025,14.150 19.775,14.375 L12.012,21.312 Z"
		// 			/>
		// 		</svg>
		// 	),
		// 	description: "Beat Rising Prices",
		// },
		{
			title: "Mobile",
			link: "/mobile",
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" width="34px" height="34px">
					<path
						fill-rule="evenodd"
						d="M29.105,27.605 L26.990,25.490 C28.108,24.378 28.994,23.054 29.597,21.597 C30.199,20.140 30.506,18.577 30.500,16.1000 C30.500,13.685 29.165,10.670 26.990,8.510 L29.105,6.395 C30.500,7.786 31.606,9.439 32.361,11.259 C33.115,13.079 33.502,15.030 33.500,16.1000 C33.500,21.140 31.820,24.890 29.105,27.605 ZM24.860,23.360 L22.745,21.245 C23.303,20.688 23.745,20.026 24.046,19.298 C24.347,18.569 24.501,17.788 24.500,16.1000 C24.500,15.335 23.825,13.835 22.745,12.755 L24.860,10.640 C25.697,11.474 26.362,12.465 26.815,13.556 C27.268,14.648 27.501,15.818 27.500,16.1000 C27.500,19.475 26.495,21.725 24.860,23.360 ZM18.500,19.1000 C17.704,19.1000 16.941,19.684 16.379,19.121 C15.816,18.558 15.500,17.796 15.500,16.1000 C15.500,16.204 15.816,15.441 16.379,14.879 C16.941,14.316 17.704,13.1000 18.500,13.1000 C19.296,13.1000 20.059,14.316 20.621,14.879 C21.184,15.441 21.500,16.204 21.500,16.1000 C21.500,17.796 21.184,18.558 20.621,19.121 C20.059,19.684 19.296,19.1000 18.500,19.1000 ZM16.1000,4.1000 L3.500,4.1000 L3.500,27.500 L16.1000,27.500 L16.1000,22.1000 L19.1000,22.1000 L19.1000,29.750 C19.1000,30.745 19.605,31.698 18.902,32.402 C18.198,33.105 17.244,33.500 16.250,33.500 L4.250,33.500 C3.255,33.500 2.302,33.105 1.598,32.402 C0.895,31.698 0.500,30.745 0.500,29.750 L0.500,4.250 C0.500,3.255 0.895,2.302 1.598,1.598 C2.302,0.895 3.255,0.500 4.250,0.500 L16.250,0.500 C17.244,0.500 18.198,0.895 18.902,1.598 C19.605,2.302 19.1000,3.255 19.1000,4.250 L19.1000,10.1000 L16.1000,10.1000 L16.1000,4.1000 Z"
					/>
				</svg>
			),
			description: "Compare hundreds of providers",
		},
		{
			title: "Motoring Insurance",
			link: "/insurance",
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="32"
					height="32"
					fill="currentColor"
					className="bi bi-car-front"
					viewBox="0 0 16 16"
				>
					<path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
					<path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z" />
				</svg>
			),
			description: "Compare car, van and bicycle insurance quotes",
		},
		{
			title: "Lifestyle Insurance",
			link: "/insurance",
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="30px">
					<path
						fill-rule="evenodd"
						d="M20.605,24.020 C18.342,27.132 15.474,29.126 11.1000,29.1000 C8.525,29.125 5.656,27.131 3.393,24.018 C1.130,20.905 -0.001,17.449 -0.000,13.650 L-0.000,4.500 L11.1000,-0.000 L23.1000,4.500 L23.1000,13.650 C23.1000,17.450 22.868,20.906 20.605,24.020 ZM11.1000,14.1000 L11.1000,3.187 L2.1000,6.562 L2.1000,14.325 C2.1000,14.500 3.025,14.725 3.075,14.1000 L11.1000,14.1000 L11.1000,26.850 C14.425,26.100 16.450,24.618 18.075,22.405 C19.700,20.192 20.650,17.724 20.925,14.1000 L11.1000,14.1000 Z"
					/>
				</svg>
			),
			description: "Compare home, pet and travel insurance quotes",
		},
	];

	return (
		<header>
			<Nav landing={true} />
			<div className={`${styles.header} d-flex flex-fill`}>
				<div className={`container py-1  d-flex flex-column flex-fill`}>
					<div className={`text-md-start text-center row flex-fill`}>
						<div className="col-12 col-lg d-flex align-items-center pb-5 mb-4 mt-4">
							{content}
						</div>
						<div className="d-none col-lg d-lg-flex align-items-center justify-content-center">
							<Image
								src={"https:" + image.fields.image.fields.file.url}
								alt={image.fields.image.fields.title}
								height="350"
								width="315"
							/>
						</div>
					</div>
				</div>
			</div>

			<div
				className={` container d-flex justify-content-center align-items-center `}
			>
				<div
					className={`${styles.roundedBox} pt-sm-5 pt-4 ps-3 pe-3 ps-sm-5 pe-sm-5 pb-sm-5 pb-4 `}
				>
					<div className={` text-center mx-auto pb-1 pt-2 col-md-9`}>
						<Heading
							title={
								"The free and easy-to-use comparison tool will help you quickly find the broadband, mobile, or insurance deals that are right for you. Switch and save today!"
							}
							isBold={false}
							variant={5}
						/>
					</div>
					<div className="row mx-auto mt-3">
						{buttonData.map((button, index) => (
							<div className="col-lg-6 mb-4" key={index}>
								<Link href={button.link}>
									<button className={`${styles.header_button} w-100 pt-3 pb-3`}>
										<div className="row align-items-center">
											<div className="col-auto ms-2 ms-sm-3">{button.icon}</div>
											<div className="col text-start">
												<Heading
													title={button.title}
													isBold={true}
													variant={5}
												/>
												<p className={`${styles.description} mb-0`}>
													{button.description}
												</p>
											</div>
											<div className="col-auto me-2 me-sm-3">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													viewBox="0 0 16 16"
												>
													<path
														fill-rule="evenodd"
														d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
													/>
												</svg>
											</div>
										</div>
									</button>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</header>
	);
};

export default LandingHeader;
