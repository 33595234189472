import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styles from "./styles.module.css";
import "slick-carousel/slick/slick-theme.css";
import { Heading } from "@/components/Shared";
import { Providers } from "@/types/providers";

const WeWorkWithInsurance: React.FC<{
	providers: Array<Providers>;
	landingPage?: boolean;
}> = ({ providers, landingPage = false }) => {
	const providerLogos = providers.map((logo: any, index: number) => {
		return (
			<div className="d-flex justify-content-center" key={index}>
				<div
					className={`mt-3 mb-5 me-3`}
					style={{
						maxHeight: "90px",
						maxWidth: "130px",
					}}
				>
					<img
						src={`https:${logo.fields.file.url}`}
						alt={`${logo.fields.title}`}
						className={`${styles.logo_image}`}
						width={"150px"}
						height={"auto"}
					/>
				</div>
			</div>
		);
	});

	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 2000,
		arrows: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		cssEase: "linear",
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3,
					infinite: true,
					dots: true,
				},
			},

			{
				breakpoint: 700,
				settings: {
					slidesToShow: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<section
			// Possibly make it mt-2 and mb-2 everywhere?
			className={` mt-${landingPage ? 1 : 5} 
			} mb-5 text-center`}
		>
			<div className="container-fluid mt-5 mb-5">
				<div>
					<div className={` heading mx-auto mb-5`}>
						<Heading
							variant={2}
							isBold={false}
							title="Compare quotes from the UK’s top leading providers"
						/>
					</div>
					<div className="text-center h-100 m-auto">
						<Slider {...sliderSettings}>{providerLogos}</Slider>
					</div>
					<div className="d-block">
						<div className={` mx-auto mt-5`}>
							<Heading variant={3} isBold={false} title="...and many more" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WeWorkWithInsurance;
