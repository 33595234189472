import * as React from "react";
import { FAQItem } from "@/types/index";
import styles from "./styles.module.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const FAQItem: React.FC<{
	increment: number;
	data: FAQItem;
}> = ({ data, increment }) => {
	const [showAnswer, setShowAnswer] = React.useState(false);
	const onClick = () => setShowAnswer((wasOpened) => !wasOpened);

	return (
		<div
			className={`${styles["faq-panel"]} ${showAnswer ? `${styles.open}` : ""}`}
			onClick={onClick}
		>
			<div className="d-flex flex-row">
				<div className={styles["faq-panel__number"]}>{increment}</div>
				<div className={styles.faq_panel__content}>
					<div className={styles["faq_panel__content-question"]}>
						<h3 className={styles["faq_panel__content-question-title"]}>
							{data.fields.question}
						</h3>
						<div className={styles["faq_panel__content-question-icon"]}>
							{showAnswer ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-dash-lg"
									viewBox="0 0 16 16"
								>
									<path
										fill-rule="evenodd"
										d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
									/>
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-plus-lg"
									viewBox="0 0 16 16"
								>
									<path
										fill-rule="evenodd"
										d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
									/>
								</svg>
							)}
						</div>
					</div>

					{showAnswer ? (
						<p className="mt-4">
							{documentToReactComponents(data.fields.answer)}
						</p>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default FAQItem;
