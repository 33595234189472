import * as React from "react";
import styles from "./styles.module.css";
import { SuperEasy } from "@/types/index";
import { Heading, CardList } from "@/components/Shared";

const SuperEasy: React.FC<{
	data: SuperEasy;
}> = ({ data }) => {
	return (
		<section className={`${styles.section}`}>
			<div className={`${styles.panel__section} container`}>
				<div className="row">
					<div className=" d-flex align-items-center">
						<div className={`${styles.heading} text-center mx-auto mb-5`}>
							<Heading variant={2} isBold={false} title={data.fields.title} />
							{data.fields.description}
						</div>
					</div>
					<div className=" col-lg  d-flex justify-content-center align-items-center">
						<div className="row col-xl-8">
							<CardList list={data.fields.usPs} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SuperEasy;
