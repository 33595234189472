import {
	BROADBAND_COMPARISON_FAQS,
	COMPARISON_HEADER_CONTENT,
	HOMEPAGE_HEADER_IMAGE,
	HOMEPAGE_SEO,
	WHY_USE_SAVELY,
	LANDING_PAGE_PROVIDERS,
} from "@/data/consts";
import {
	FAQS,
	PageSEO as PageSEOType,
	SuperEasy as SuperEasyType,
} from "@/types/index";
import {
	HeaderContent as HeaderContentType,
	HeaderImage as HeaderImageType,
} from "@/types/header";
import * as React from "react";
import FAQs from "@/components/FAQs";
import Footer from "@/components/Footer";
import { getOne } from "@/api/contentful";
import { Providers } from "@/types/providers";
import SuperEasy from "@/components/SuperEasy";
import PageSEO from "@/components/Shared/PageSEO";
import MailingList from "@/components/MailingList";
import LandingHeader from "@/components/Header/LandingHeader";
import HeaderContent from "@/components/Comparison/HeaderContent";
import WeWorkWithInsurance from "@/components/Insurance/WeWorkWithInsurance";

const CompareBroadband: React.FC<{
	pageSEO: PageSEOType;
	headerContent: HeaderContentType;
	headerImage: HeaderImageType;
	FAQsContent: FAQS;
	superEasy: SuperEasyType;
	providers: {
		fields: {
			providerLogos: Array<Providers>;
		};
	};
}> = ({
	pageSEO,
	headerContent,
	headerImage,
	FAQsContent,
	superEasy,
	providers,
}) => {
	return (
		<div className="h-100 d-flex flex-column">
			<PageSEO
				title={pageSEO.fields.pageTitle}
				description={pageSEO.fields.metaDescription}
				keywords={pageSEO.fields.metaKeywords}
			/>
			<LandingHeader
				content={<HeaderContent data={headerContent} />}
				image={headerImage}
			/>
			{/* Replace WeWorkWith with WeWorkWithInsurance and then rename WeWorkWithInsurance to WeWorkWith since its reusable */}
			<WeWorkWithInsurance
				providers={providers.fields.providerLogos}
				landingPage={true}
			/>
			<SuperEasy data={superEasy} />
			{/* <TrustPilot /> */}
			<FAQs data={FAQsContent} />
			<MailingList />
			<Footer />
		</div>
	);
};

export async function getStaticProps() {
	const pageSEO = await getOne(HOMEPAGE_SEO);
	const headerContent = await getOne(COMPARISON_HEADER_CONTENT);
	const headerImage = await getOne(HOMEPAGE_HEADER_IMAGE);
	const FAQsContent = await getOne(BROADBAND_COMPARISON_FAQS);
	const superEasy = await getOne(WHY_USE_SAVELY);
	const providers = await getOne(LANDING_PAGE_PROVIDERS);

	return {
		props: {
			pageSEO,
			headerContent,
			headerImage,
			FAQsContent,
			superEasy,
			providers,
		},
		revalidate: Number(process.env.REVALIDATE_CONTENTFUL),
	};
}

export default CompareBroadband;
