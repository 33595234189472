import * as React from "react";
import FAQList from "./FAQList";
import { FAQS } from "@/types/index";
import styles from "./styles.module.css";
import { Heading } from "@/components/Shared";

const FAQs: React.FC<{
	data: FAQS;
}> = ({ data }) => {
	return (
		<section className={styles.faq}>
			<div className="container ">
				<div className="row ">
					<div className="col-12 text-center pb-5">
						<Heading title={data.fields.title} variant={2} isBold={false} />
					</div>
					<div className="d-flex flex-column align-items-center">
						<div className="w-75">
							<FAQList list={data.fields.faqItem} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FAQs;
